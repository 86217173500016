import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';

export default {
    name: 'FairyTalesCreate',
    data() {
        return {
            poem: {
                name: null,
                body: null,
                author: null
            },
            pages: [
                {
                    number: 1,
                    title: null,
                    content: null,
                    cover_image: null,
                    image: null,
                    audio: null
                }
            ],
            fileCoverImage: null
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout
    },
    methods: {
        createPoem() {
            const self = this
            let formData = new FormData()

            formData.append('name', self.poem.name)
            formData.append('body', self.poem.body)
            formData.append('author', self.poem.author)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)

            Object.keys(self.pages).forEach(function(key, index) {
                formData.append('pages[' + index + '][number]', self.pages[index]['number'])
                formData.append('pages[' + index + '][title]', self.pages[index]['title'])
                formData.append('pages[' + index + '][content]', self.pages[index]['content'])
                formData.append('pages[' + index + '][cover_image]', self.pages[index]['cover_image'])
                formData.append('pages[' + index + '][image]', self.pages[index]['image'])
                formData.append('pages[' + index + '][audio]', self.pages[index]['audio'])
            })
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/fairy-tales',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.fairytales' })

                self.notifySuccess()
            })
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        },
        appendNewPage() {
            this.pages.push({
                number: this.pages.length + 1,
                title: null,
                content: null,
                cover_image: null,
                image: null,
                audio: null
            })
        },
        deletePage(e) {
            let index = e.currentTarget.getAttribute('data-index')
            let totalPages = this.pages.length

            if(totalPages <= 1) {
                return this.notifyWarning('Minimum pages is 1!')
            }

            return this.pages.splice(index, 1)
        },
        handlePageFileCoverImageUpload(e) {
            let index = e.currentTarget.getAttribute('data-index')
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[index].cover_image = null
            }

            this.pages[index].cover_image = files[0]
        },
        handlePageFileMainImageUpload(e) {
            let index = e.currentTarget.getAttribute('data-index')
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[index].image = null
            }

            this.pages[index].image = files[0]
        },
        handlePageFileAudioUpload(e) {
            let index = e.currentTarget.getAttribute('data-index')
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[index].audio = null
            }

            this.pages[index].audio = files[0]
        },
    }
}